import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const PasswordSetup = () => {
    const { parentId, token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const handlePasswordChange = (e: any) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e: any) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setErrorMessage("Passwords do not match");
            return;
        }

        try {
            const response = await axios.post(`/server/sports_business_info/api/setPassword/${parentId}/${token}`, { password });
            setSuccessMessage(response.data.message);
            setErrorMessage('');

            // Redirect to login page after a short delay
            setTimeout(() => {
                navigate('/login');
            }, 3000);

        } catch (error:any) {
            console.error('Error setting password:', error);
            setErrorMessage(error.response?.data?.error || 'An error occurred while setting your password');
        }
    };

    return (
        <div className="password-setup-container">
            <h2>Set Up Your Password</h2>
            <form onSubmit={handleSubmit} className="password-setup-form">
                <div className="form-group">
                    <label htmlFor="password">New Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password:</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        required
                        className="form-control"
                    />
                </div>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                {successMessage && <p className="success-message">{successMessage}</p>}
                <button type="submit" className="btn btn-primary">Set Password</button>
            </form>
        </div>
    );
};

export default PasswordSetup;
