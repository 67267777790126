export function LeaveScreen({ setIsMeetingLeft,setRejoinMeetingId,setMeetingId }) {
    return (
        <div className="bg-gray-800 h-screen flex flex-col flex-1 items-center justify-center" style={{backgroundColor:'transparent'}}>
            <h1 className="text-white text-4xl">You left the meeting!</h1>
            <div className="mt-12">
                <button
                    className="`w-full bg-purple-350 text-white px-16 py-3 rounded-lg text-sm"
                    onClick={() => {
                        setIsMeetingLeft(false);
                        setMeetingId(setRejoinMeetingId)
                    }}
                >
                    Rejoin the Meeting
                </button>
            </div>
        </div>
    );
}
