import React, { useState } from 'react';
import axios from 'axios';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import './registrationForm.css'; // Ensure this file includes the necessary CSS

const LoginForm = ({ show, handleClose }: { show: boolean; handleClose: () => void }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log('Login form submitted.');
        try {
            const response = await axios.post('/server/sports_business_info/api/login/email', { email, password });
            const { accessToken, teamName, permission, meetingId, leagueName } = response.data;

            localStorage.setItem('accessToken', accessToken);

            if (permission) {
                console.log('Redirecting to team stream...');
                navigate(`/app/teamStream/${leagueName}/${teamName}`, {
                    state: {
                        email,
                        accessToken,
                        teamName,
                        permission,
                        meetingId,
                        leagueName,
                    },
                });
            } else {
                setError('Permission denied.');
            }
        } catch (error) {
            setError('Invalid email or password');
            console.error('Login failed:', error);
        }
    };

    const handleSuccess = async (response: any) => {
        console.log('Google login successful in LoginForm, handling response...');
        try {
            const idToken = response.credential;
            const backendResponse = await axios.post(`/server/sports_business_info/api/login`, { idToken });
            const { accessToken, teamName, permission, email, meetingId, leagueName } = backendResponse.data;

            localStorage.setItem('accessToken', accessToken);

            if (permission) {
                console.log('Redirecting to team stream...');
                navigate(`/app/teamStream/${leagueName}/${teamName}`, {
                    state: {
                        email,
                        accessToken,
                        teamName,
                        permission,
                        meetingId,
                        leagueName,
                    },
                });
            } else {
                setError('Permission denied.');
            }
        } catch (error) {
            setError('Google login failed. Please try again.');
            console.error('Google login failed:', error);
        }
    };

    const handleError = (error: any) => {
        setError('Google login failed. Please try again.');
        console.error('Google login failed:', error);
    };

    const handleAlreadyRegistered = () => {
        window.location.reload(); // Refresh the page
    };

    return (
        <>
            <div className={`modal ${show ? 'show' : ''}`} tabIndex={-1} role="dialog" style={{ display: show ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content envelope">
                        <div className="modal-header">
                            <h5 className="modal-title">Login Form</h5>
                            <button type="button" className="close" onClick={handleClose} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleLogin}>
                                <div className="form-group mb-3">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="password">Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary btn-block">Login</button>
                                {error && <div className="text-danger mt-2">{error}</div>}
                            </form>
                            <hr />
                            <div className="text-center mb-4">
                                <h4>Or Login with Google</h4>
                                <GoogleLogin
                                    // @ts-ignore
                                    clientId="179478627002-th39iebli3b17dg5mkj4vu32sneo8mt9.apps.googleusercontent.com"
                                    onSuccess={handleSuccess}
                                    onFailure={handleError}
                                />
                            </div>
                            <hr />
                            <div className="text-center mb-4">
                                <button
                                    className="btn btn-secondary"
                                    onClick={handleAlreadyRegistered}
                                >
                                    Already Registered? Sign In Here
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginForm;
