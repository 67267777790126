import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from 'react-router-dom';
import RegistrationForm from '../TeamStream/registration'
import Gif from '../appstore-login/TeamStreamFinal.gif'
import LoginForm from '../TeamStream/login'; // Import the LoginForm component

const AppStore = () => {
    const [accessToken, setAccessToken] = useState('');
    const [leagueName, setLeagueName] = useState('');
    const [teamName, setTeamName] = useState('');
    const [email, setEmail] = useState('');
    const [meetingId, setMeetingId] = useState('');
    const [permission, setPermission] = useState(null);
    const [showRegistration, setShowRegistration] = useState(true);
    const [showLogin, setShowLogin] = useState(false); // State for controlling login modal visibility
    const navigate = useNavigate();

    const handleSuccess = async (response: any) => {
        try {
            const idToken = response.credential;
            const backendResponse = await axios.post(`/server/sports_business_info/api/login`, { idToken });
            const { accessToken, teamName, permission, email, meetingId, leagueName } = backendResponse.data;

            localStorage.setItem('accessToken', accessToken);
            setAccessToken(accessToken);
            setTeamName(teamName);
            setEmail(email);
            setPermission(permission);
            setMeetingId(meetingId);
            setLeagueName(leagueName);

            console.log('Organizer response is', response);
            console.log('Organizer accessToken is ', accessToken);
            console.log('Organizer team name is ', teamName);
            console.log('Organizer league is ', leagueName);
            console.log('Organizer permission is ', permission);

            if (!permission) {
                setShowRegistration(true);
                console.log(permission);
            }
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    useEffect(() => {
        console.log(accessToken); // Log the access token state when it changes
    }, [accessToken]);

    const handleError = (error: any) => {
        console.error('Google login failed:', error);
    };

    useEffect(() => {
        if (accessToken && teamName && permission && email && leagueName) {
            navigate(`/app/teamStream/PrestonwoodSoccer/${teamName}`, {
                state: {
                    email,
                    accessToken,
                    teamName,
                    permission,
                    meetingId,
                    leagueName
                }
            });
        }
        console.log('Here is the email you are looking for', email);
        console.log('Here is the teamName you are looking for', teamName);
    }, [accessToken, teamName, navigate, email, permission]);

    useEffect(() => {
        if (!showRegistration) {
            setShowLogin(true);
        }
    }, [showRegistration]);

    return (
        <div>
            <h1 style={{textAlign:'center'}}>Team Stream Login</h1>
            <div className="evanston-tavern teamstream-content-container">
                With the touch of a button you can create your own virtual learning center.  Stream or store content from different angles to your cloud device for members of your team.
            </div>
            <div className="hero-container">
                <img src={Gif} style={{ width: '100%' }} alt="Youth Sports" className="hero-video" />

                <div className="hero-content" style={{ backgroundColor: 'rgb(47, 47, 47)', color: 'white' }}>

                </div>
            </div>
        {/*    <GoogleLogin
                // @ts-ignore
                clientId="179478627002-th39iebli3b17dg5mkj4vu32sneo8mt9.apps.googleusercontent.com"
                onSuccess={handleSuccess}
                onFailure={handleError}
            />*/}
            {showRegistration && <RegistrationForm show={showRegistration} handleClose={() => setShowRegistration(false)} />}
            {showLogin && <LoginForm show={showLogin} handleClose={() => setShowLogin(false)} />}
        </div>
    );
};

export default AppStore;
