import './teamstream.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import rokuEmoji from "../roku.svg";
import { useNavigate } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
  ButtonGroup
} from "react-bootstrap";
import Gif from "../appstore-login/TeamStreamFinal.gif";

function Dashboard({ Email, LeagueName, TeamName, AccessToken, Permission, JoinLiveButton, MeetingId }) {
  const [videos, setVideos] = useState([]);
  const [selections, setSelections] = useState({
    clubhouseSportsNews: '',
    podcasts: '',
    proSportsNews: '',
    editorialVideos: ''
  });

  const navigate = useNavigate();

  // Fetch team stream recordings
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.post('/server/sports_business_info/api/TeamStreamRecordings', {
          teamName: TeamName,
          leagueName: LeagueName
        });
        setVideos(response.data);
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchVideos();
  }, [TeamName, LeagueName]);

  // Fetch additional API data if needed
  useEffect(() => {
    const fetchUserStoredRokuChannels = async () => {
      try {
        // Make sure to use params to send query parameters
        const response = await axios.get('/server/sports_business_info/api/getUserStoredRokuChannels', {
          params: {
            email: Email,
            teamName: TeamName,
            leagueName: LeagueName
          }
        });

        if (response.data) {
          console.log(response.data);
          setSelections(prevState => ({
            ...prevState,
            ...response.data
          }));
        }
      } catch (error) {
        console.error('Error fetching user stored Roku channels:', error);
      }
    };

    fetchUserStoredRokuChannels();
  }, [Email, LeagueName, TeamName]); // Incl
  useEffect(() => {
    const saveSelections = async () => {
      try {
        await axios.post('/server/sports_business_info/api/saveSelections', {
          email: Email,
          teamName: TeamName,
          leagueName: LeagueName,
          ...selections // Spread operator to include all selections fields
        });
      } catch (error) {
        console.error('Error saving selections:', error);
      }
      console.log(selections)
    };

    const handleBeforeUnload = (event) => {
      // Prevent the default unload behavior
      event.preventDefault();
      // Trigger the save function
      saveSelections();
      // Return a string to show a confirmation dialog (optional)
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      // Optionally call saveSelections when component unmounts
      saveSelections();
    };
  }, [selections]);


  const handleJoinClick = () => {
    if (AccessToken && TeamName && Permission && Email && LeagueName && MeetingId && JoinLiveButton) {
      navigate(`/app/LeagueStream`, {
        state: {
          Email,
          AccessToken,
          TeamName,
          Permission,
          MeetingId,
          LeagueName,
          JoinLiveButton
        }
      });
    } else {
      console.log('All required information is not available');
    }
  };

  const handleCreateClick = () => {
    if (AccessToken && TeamName && Permission && Email && LeagueName) {
      navigate(`/app/LeagueStream`, {
        state: {
          Email,
          AccessToken,
          TeamName,
          Permission,
          LeagueName,
        }
      });
    } else {
      console.log('All required information is not available');
    }
  };

  const handleSelection = (category, selection) => {
    setSelections(prevState => ({
      ...prevState,
      [category]: selection
    }));
  };

  return (
      <>
        <Container fluid className="evanston-tavern">
          <Row className='roku-row'>
            {['clubhouseSportsNews', 'podcasts', 'proSportsNews', 'editorialVideos'].map((category, index) => {
              // Define gradient styles for each category
              const gradientStyles = {
                clubhouseSportsNews: { '--gradient-start': '#2f2f2f', '--gradient-end': 'rgb(255, 153, 102)' },
                podcasts: { '--gradient-start': '#2f2f2f', '--gradient-end': 'rgb(252, 103, 250)' },
                proSportsNews: { '--gradient-start': '#2f2f2f', '--gradient-end': 'cadetblue' },
                editorialVideos: { '--gradient-start': '#2f2f2f', '--gradient-end': 'rgb(106, 17, 203)' }
              };

              return (
                  <Col lg="3" sm="6" key={index}>
                    <Card className="card-stats card-container">
                      <Card.Body className='roku-c-body'>
                        <Row>
                          <Col xs="7">
                            <img src={rokuEmoji} alt="roku" style={{ width: '100%', height: 'auto', border: 'none' }} />
                            <div className="numbers">
                              <p className="card-category">Personalized Roku Feed #{index + 1}</p>
                              <div className="button-group" style={gradientStyles[category]}>
                                <button className="selected-category-button" onClick={() => handleSelection(category, 'Some Selection')}>
                                  {selections[category] || 'Select an option'}
                                </button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                      <Card.Footer>
                        <hr />
                        <div className="stats">
                          <i className="fas fa-redo mr-1"></i>
                          {category.charAt(0).toUpperCase() + category.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                        </div>
                      </Card.Footer>
                    </Card>
                  </Col>
              );
            })}
          </Row>

          <Row>
            <Col md="6">
              <Card className="teamstream-card-container">
                <Card.Header className="teamstream-card-header" style={{ backgroundImage: 'linear-gradient(to right, rgb(255, 126, 95), rgb(254, 180, 123))', height: '9em' }}>
                  <Card.Title as="h4">Team Stream</Card.Title>
                  <Col xs={12}>
                    <div className="numbers">
                      <p className="card-category">{Email}</p>
                      <p className="card-category">{LeagueName}</p>
                      <p className="card-category">{TeamName}</p>
                    </div>
                  </Col>
                </Card.Header>
                <Card.Body className="teamstream-card-body" style={{ padding: '0px' }}>
                  <div className="hero-video-container">
                    <img src={Gif} alt="Youth Sports" className="hero-video" />
                    <div className="center-button">
                      {JoinLiveButton ? (
                          <Button variant="danger" onClick={handleJoinClick} className="card-category">Join Live Team Stream</Button>
                      ) : (
                          <Button variant="danger" onClick={handleCreateClick} className="card-category">Create a Meeting</Button>
                      )}
                    </div>
                  </div>
                </Card.Body>

                <Card.Body style={{ backgroundColor: '#2f2f2f' }} className="teamstream-card-info">
                  <Row style={{ backgroundImage: 'linear-gradient(to right, rgb(255, 126, 95), rgb(254, 180, 123))' }}>
                    <Col xs={12}>
                      <h1>Video Links</h1>
                      <Card.Body className='roku-c-body'>
                        <Row>
                          <Col xs="7">
                            <img src={rokuEmoji} alt="roku" style={{ width: '100%', height: 'auto', border: 'none' }} />
                            <div className="numbers">
                              <p className="card-category">Roku Team Stream Channel</p>
                              <Card.Title as="h4">Watch your team on Roku</Card.Title>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                      <div className="table-responsive">
                        <Table striped bordered hover className="wrapped-table">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th>Videos</th>
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          {videos?.map((video, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td className="wrapped-cell">
                                  <a href={video.url} download target="_blank" rel="noopener noreferrer">
                                    {video.public_id}
                                  </a>
                                </td>
                                <td className="td-actions text-right">
                                  <OverlayTrigger
                                      overlay={<Tooltip id={`tooltip-edit-${index}`}>Edit Task..</Tooltip>}
                                  >
                                    <Button className="btn-simple btn-link p-1" type="button" variant="info">
                                      <i className="fas fa-edit"></i>
                                    </Button>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                      overlay={<Tooltip id={`tooltip-remove-${index}`}>Remove..</Tooltip>}
                                  >
                                    <Button className="btn-simple btn-link p-1" type="button" variant="danger">
                                      <i className="fas fa-times"></i>
                                    </Button>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                          ))}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col md="6">
              <Card className="roku-channel-container">
                <Card.Header className="roku-card-header" style={{ backgroundImage: 'linear-gradient(to right, rgb(106, 17, 203), rgb(37, 117, 252))', height: '9em' }}>
                  <Card.Title as="h4">Personal Roku Interests Feed</Card.Title>
                  <span className="card-category">Choose 1 channel option for each section of your Roku Stream</span>
                  <span>Download Roku now and look for the channel Clubhouse Links. Then Sign into your account to see your personalized entertainment channel.</span>
                </Card.Header>
                <Card.Body className="roku-channels-card-body">
                  <div className="container">
                    {/* First Column */}
                    <div className="column">
                      <h3 className="roku-selection">Clubhouse Sports News</h3>
                      <div className="button-group" style={{ '--gradient-start': '#2f2f2f', '--gradient-end': 'rgb(255, 153, 102)' }}>
                        <button onClick={() => handleSelection('clubhouseSportsNews', 'Sports Ownership')}>Sports Ownership</button>
                        <button onClick={() => handleSelection('clubhouseSportsNews', 'Sports Technology')}>Sports Technology</button>
                        <button onClick={() => handleSelection('clubhouseSportsNews', 'Sports Technology Stock')}>Sports Technology Stock</button>
                        <button onClick={() => handleSelection('clubhouseSportsNews', 'Name Image Likeness')}>Name Image Likeness</button>
                        <button onClick={() => handleSelection('clubhouseSportsNews', 'Youth Sports')}>Youth Sports</button>
                        <button onClick={() => handleSelection('clubhouseSportsNews', 'Local Sports')}>Local Sports</button>
                      </div>
                    </div>
                    {/* Second Column */}
                    <div className="column">
                      <h3 className="roku-selection">Podcasts</h3>
                      <div className="button-group" style={{ '--gradient-start': '#2f2f2f', '--gradient-end': 'rgb(252, 103, 250)' }}>
                        <button onClick={() => handleSelection('podcasts', 'G.O.A.T.S.')}>The G.O.A.T.S.</button>
                        <button onClick={() => handleSelection('podcasts', 'Sports Fashion')}>Sports Fashion</button>
                        <button onClick={() => handleSelection('podcasts', 'Pros vs Joes')}>Pros vs Joes</button>
                        <button onClick={() => handleSelection('podcasts', 'True Crime Sports')}>True Crime Sports</button>
                        <button onClick={() => handleSelection('podcasts', 'Sports Tea')}>Sports Tea</button>
                        <button onClick={() => handleSelection('podcasts', 'Parents Know Best')}>Parents Know Best</button>
                      </div>
                    </div>
                    {/* Third Column */}
                    <div className="column">
                      <h3 className="roku-selection">Pro Sports News</h3>
                      <div className="button-group" style={{ '--gradient-start': '#2f2f2f', '--gradient-end': 'cadetblue' }}>
                        <button onClick={() => handleSelection('proSportsNews', 'NFL News')}>NFL News</button>
                        <button onClick={() => handleSelection('proSportsNews', 'NBA News')}>NBA News</button>
                        <button onClick={() => handleSelection('proSportsNews', 'MLB News')}>MLB News</button>
                        <button onClick={() => handleSelection('proSportsNews', 'MLS News')}>MLS News</button>
                        <button onClick={() => handleSelection('proSportsNews', 'WNBA News')}>WNBA News</button>
                      </div>
                    </div>
                    {/* Fourth Column */}
                    <div className="column">
                      <h3 className="roku-selection">Editorial Videos</h3>
                      <div className="button-group" style={{ '--gradient-start': '#2f2f2f', '--gradient-end': 'rgb(106, 17, 203)' }}>
                        <button onClick={() => handleSelection('editorialVideos', 'Athlete Interviews')}>Athlete Interviews</button>
                        <button onClick={() => handleSelection('editorialVideos', 'Women In Sports')}>Women In Sports</button>
                        <button onClick={() => handleSelection('editorialVideos', 'Black Excellence')}>Black Excellence</button>
                        <button onClick={() => handleSelection('editorialVideos', "Today's Modern Athlete")}>Today's Modern Athlete</button>
                        <button onClick={() => handleSelection('editorialVideos', 'Forgotten Teams')}>Forgotten Teams</button>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
  );
}

export default Dashboard;
