import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { InView } from 'react-intersection-observer';
import { ArticleContext } from '../../App';
import LoadingIcon from "../screens/WaitingToJoinScreen";

interface Article {
    id: string;
    url: string;
    title: string;
    content_text: string;
    content_html: string;
    image: string;
    date_published: string;
    authors: { name: string }[];
    attachments: { url: string }[];
}

const NewspaperArticles = ({
                               serpApiArticles,
                               serpApiArticlesNoGif,
                               onHeightChange,
                           }: {
    serpApiArticles: any[];
    serpApiArticlesNoGif: any[];
    onHeightChange: (height: number) => void;
}) => {
    const [selectedSport, setSelectedSport] = useState('');
    const [fetchedArticles, setFetchedArticles] = useState<Article[]>([]);
    const [loading, setLoading] = useState(true);
    const [showSerpApiArticles, setShowSerpApiArticles] = useState(true);
    const { setSelectedArticle } = useContext(ArticleContext);
    const containerRef = useRef<HTMLDivElement>(null);

    const rssFeeds: { [key: string]: string } = {
        one: 'https://rss.app/feeds/v1.1/tk1GBzUy7UDcieGu.json',
        two: 'https://rss.app/feeds/v1.1/tpNa22IIsHMEOnYG.json',
        three: 'https://rss.app/feeds/v1.1/tIHBruIjHiARWNdI.json',
        four: 'https://rss.app/feeds/v1.1/tIVmYKqL4S9IBLCb.json',
    };

    const fetchArticles = async (sport: string) => {
        try {
            const response = await axios.get(rssFeeds[sport]);
            const articles: Article[] = response.data.items;
            const uniqueArticles = articles.filter(
                (article, index, self) => index === self.findIndex((a) => a.title === article.title)
            );
            setFetchedArticles(uniqueArticles);
        } catch (error) {
            console.error(`Error fetching ${sport} articles:`, error);
        }
    };

    useEffect(() => {
        if (selectedSport) {
            fetchArticles(selectedSport);
        }
    }, [selectedSport]);

    useEffect(() => {
        fetchArticles('one'); // Fetch sports Data Analytics articles by default
    }, []);

    const navigateToArticle = (url: string) => {
        window.open(url, '_blank');
    };

    useEffect(() => {
        const handleHeightChange = () => {
            if (containerRef.current) {
                onHeightChange(containerRef.current.clientHeight);
            }
        };

        handleHeightChange(); // Initial height calculation

        const timeout = setTimeout(() => {
            handleHeightChange();
        }, 500); // Adjust timeout as needed based on content loading speed

        return () => clearTimeout(timeout);
    }, [fetchedArticles, serpApiArticles, onHeightChange]);

    useEffect(() => {
        if (serpApiArticles.length < 1 && serpApiArticlesNoGif.length < 1) {
            const timeout = setTimeout(() => {
                setLoading(false);
                setShowSerpApiArticles(false);
                setSelectedSport('sportsDataAnalytics');
            }, 3000);

            return () => clearTimeout(timeout);
        } else {
            setLoading(false);
        }
    }, [serpApiArticles, serpApiArticlesNoGif]);

    if (loading) {
        return <LoadingIcon />;
    }

    const handleShowSerpApiArticles = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        event.preventDefault(); // Prevent default behavior of anchor tag
        setShowSerpApiArticles(true);
        setSelectedSport(''); // Reset selected sport when showing SERP API articles
    };

    const handleSportSelection = (sport: string, event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        event.preventDefault(); // Prevent default behavior of anchor tag
        setSelectedSport(sport);
        setShowSerpApiArticles(false); // Reset to hide SERP API articles when selecting a sport
    };

    const gradients: { [key: string]: string } = {
       one: 'linear-gradient(to right, #ff7e5f, #feb47b)',
        two: 'linear-gradient(to right, #6a11cb, #2575fc)',
        four: 'linear-gradient(to right, #43cea2, #185a9d)',
        three: 'linear-gradient(to right, #ff9966, #ff5e62)',
    };

    return (
        <div>
            <nav
                className="nav nav-pills nav-fill"
                style={{ backgroundColor: 'black', border: 'solid', fontFamily: 'MyCustomFont' }}
            >
                <span
                    className={`nav-item nav-link ${showSerpApiArticles ? 'active' : ''} w-100`}
                    onClick={handleShowSerpApiArticles}
                    style={{
                        fontFamily: 'MyCustomFont3',
                        borderRadius: '0px',
                        cursor: 'pointer',
                        color: 'white',
                        background: showSerpApiArticles ? 'linear-gradient(to right, #044e70, #00254f)' : 'linear-gradient(to right, #ffd700, #b8860b)',
                        display: 'block',
                        padding: '0.5rem 1rem',
                    }}
                >
                   SPORTS OWNERSHIP
                </span>
                {['one', 'two', 'four', 'three'].map((sport) => (
                    <span
                        key={sport}
                        className={`nav-item nav-link ${selectedSport === sport && !showSerpApiArticles ? 'active' : ''} w-100`}
                        onClick={(e) => handleSportSelection(sport, e)}
                        style={{
                            borderRadius: '0px',
                            marginTop: '1px',
                            marginBottom: '1px',
                            border: 'solid',
                            borderWidth: '1.5px',
                            cursor: 'pointer',
                            color: 'white',
                            background: selectedSport === sport && !showSerpApiArticles ? 'linear-gradient(to right, #044e70, #00254f)' : gradients[sport],
                            display: 'block',
                            padding: '0.5rem 1rem',
                        }}
                    >
                        {sport === 'one' ? 'Sports Transactions' :
                            sport === 'two' ? 'Media Rights' :
                                sport === 'four' ? 'Top Owners' :
                                sport === 'three' ? 'Team Acquisitions' :

                                        sport.charAt(0).toUpperCase() + sport.slice(1)}
                    </span>
                ))}
            </nav>
            <div ref={containerRef}>
                {showSerpApiArticles ? (
                    <>
                        {serpApiArticles.map((article, index) => (
                            <div key={index} style={{ margin: '20px 0' }}>
                                <div
                                    className="head"
                                    style={{
                                        color: 'white',
                                        borderColor: 'white',
                                        backgroundColor: 'black',
                                        borderStyle: 'solid',
                                        padding: '.5em',
                                        fontWeight: '800',
                                        fontSize: '24px',
                                        borderWidth: '.1em',
                                        boxSizing: 'border-box',
                                        textAlign: 'center',
                                        borderRadius: '.2em'
                                    }}
                                >
                                    <span style={{ fontFamily: 'MyCustomFont3' }} className="headline hl3">{article.SportsOwnership.title}</span>
                                </div>
                                <InView>
                                    {({ inView, ref }) => (
                                        <img
                                            onClick={() => navigateToArticle(article.SportsOwnership.url)}
                                            src={article.SportsOwnership.url}
                                            style={{ width: '100%', cursor: 'pointer' }}
                                            alt={article.SportsOwnership.title}
                                            ref={ref}
                                        />
                                    )}
                                </InView>
                                <p style={{ fontFamily: 'MyCustomFont2', fontWeight: '300', marginBottom: '2em', color: 'darkslategray', textIndent: '2em' }} className="newspaper-description">{article.SportsOwnership.description}</p>
                            </div>
                        ))}
                        {serpApiArticlesNoGif.map((article, index) => (
                            <div key={index} style={{ margin: '20px 0' }}>
                                <div
                                    className="head"
                                    style={{
                                        color: 'white',
                                        borderColor: 'white',
                                        backgroundColor: 'black',
                                        borderStyle: 'solid',
                                        padding: '.5em',
                                        fontWeight: '800',
                                        fontSize: '24px',
                                        borderWidth: '.1em',
                                        boxSizing: 'border-box',
                                        textAlign: 'center',
                                        borderRadius: '.2em'
                                    }}
                                >
                                    <span style={{ fontFamily: 'MyCustomFont3' }} className="headline hl3">{article.SportsOwnershipNoGif.title}</span>
                                </div>
                                <InView>
                                    {({ inView, ref }) => (
                                        <img
                                            onClick={() => navigateToArticle(article.SportsOwnershipNoGif.url)}
                                            src={article.SportsOwnershipNoGif.url}
                                            style={{ width: '100%', cursor: 'pointer' }}
                                            alt={article.SportsOwnershipNoGif.title}
                                            ref={ref}
                                        />
                                    )}
                                </InView>
                                <p style={{ fontFamily: 'MyCustomFont2', fontWeight: '300', marginBottom: '2em', color: 'darkslategray', textIndent: '2em' }} className="newspaper-description">{article.SportsOwnershipNoGif.description}</p>
                            </div>
                        ))}
                    </>
                ) : (
                    fetchedArticles.map((article, index) => (
                        <div key={index} style={{ margin: '20px 0' }}>
                            <div
                                className="head"
                                style={{
                                    color: 'white',
                                    borderColor: 'white',
                                    backgroundColor: 'black',
                                    borderStyle: 'solid',
                                    padding: '.5em',
                                    fontWeight: '800',
                                    fontSize: '24px',
                                    borderWidth: '.1em',
                                    boxSizing: 'border-box',
                                    textAlign: 'center',
                                    borderRadius: '.2em'
                                }}
                            >
                                <span style={{ fontFamily: 'MyCustomFont3' }} className="headline hl3">{article.title}</span>
                            </div>
                            <InView>
                                {({ inView, ref }) => (
                                    <img
                                        onClick={() => navigateToArticle(article.url)}
                                        src={article.image}
                                        style={{ width: '100%', cursor: 'pointer' }}
                                        alt={article.title}
                                        ref={ref}
                                    />
                                )}
                            </InView>
                            <p style={{ fontFamily: 'MyCustomFont2', fontWeight: '300', marginBottom: '2em', color: 'darkslategray', textIndent: '2em' }} className="newspaper-description">{article.content_text}</p>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default NewspaperArticles;
