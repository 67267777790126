import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './registrationForm.css'; // Import custom CSS for animation


const RegistrationForm = ({ show, handleClose }: { show: boolean; handleClose: () => void }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [teamName, setTeamName] = useState('');
    const [email, setEmail] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [accessToken, setAccessToken] = useState('');
    const [leagueName, setLeagueName] = useState('');
    const [meetingId, setMeetingId] = useState('');
    const [permission, setPermission] = useState(null)
    const [showRegistration, setShowRegistration] = useState(true);
    const [showLogin, setShowLogin] = useState(false); // State for controlling login modal visibility
    const navigate = useNavigate();

    // Function to handle success in Google login
    const handleSuccess = async (response: any) => {
        try {
            const idToken = response.credential; // Access the credential directly
            const backendResponse = await axios.post(`/server/sports_business_info/api/login`, { idToken });
            const { accessToken, teamName, permission, email, meetingId, leagueName } = backendResponse.data;

            localStorage.setItem('accessToken', accessToken);
            setAccessToken(accessToken);
            setTeamName(teamName);
            setEmail(email);
            setPermission(permission);
            setMeetingId(meetingId);
            setLeagueName(leagueName);

            console.log('Organizer response is', response);
            console.log('Organizer accessToken is ', accessToken);
            console.log('Organizer team name is ', teamName);
            console.log('Organizer league is ', leagueName);
            console.log('Organizer permission is ', permission);

            if (!permission) {
                setShowRegistration(true);
                console.log(permission);
            }
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    useEffect(() => {
        console.log(accessToken); // Log the access token state when it changes
    }, [accessToken]);

    // Function to handle error in Google login
    const handleError = (error: any) => {
        console.error('Google login failed:', error);
    };

    useEffect(() => {
        if (accessToken && teamName && permission && email && leagueName) {
            navigate(`/app/teamStream/${leagueName}/${teamName}`, {
                state: {
                    email,
                    accessToken,
                    teamName,
                    permission,
                    meetingId,
                    leagueName,
                },
            });
        }
        console.log('Here is the email you are looking for', email);
        console.log('Here is the teamName you are looking for', teamName);
    }, [accessToken, teamName, navigate, email, permission]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!firstName) {
            setFirstNameError('First name is required');
            return;
        } else {
            setFirstNameError('');
        }

        if (!lastName) {
            setLastNameError('Last name is required');
            return;
        } else {
            setLastNameError('');
        }

        const name = `${firstName} ${lastName}`;

        try {
            const response = await axios.post('/server/sports_business_info/api/registerParent', {
                email,
                name,
            });

            console.log('Parent registered successfully:', response.data);
        } catch (error) {
            console.error('Error registering parent:', error);
        }

        setFirstName('');
        setLastName('');
        setTeamName('');
        setEmail('');
    };

    // Function to switch to the login modal
    const handleSwitchToLogin = () => {
        handleClose(); // Close the registration modal
        setShowLogin(true); // Open the login modal
    };

    // Function to close the login modal
    const handleLoginClose = () => {
        setShowLogin(false);
    };

    return (
        <>
            <div className={`modal ${show ? 'show' : ''}`} tabIndex={-1} role="dialog" style={{ display: show ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content envelope">
                        <div className="modal-header">
                            <h5 className="modal-title">Registration Form</h5>
                            <button type="button" className="close" onClick={handleClose} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group mb-3">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="firstName">First Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="firstName"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        required
                                    />
                                    {firstNameError && <div className="text-danger">{firstNameError}</div>}
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="lastName">Last Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="lastName"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        required
                                    />
                                    {lastNameError && <div className="text-danger">{lastNameError}</div>}
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="teamName">Team Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="teamName"
                                        value={teamName}
                                        onChange={(e) => setTeamName(e.target.value)}
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary btn-block">Submit</button>
                            </form>
                            <hr />
                            <div className="text-center mb-4">
                                <h4>Or Sign In with Google</h4>
                                <GoogleLogin
                                    // @ts-ignore
                                    clientId="179478627002-th39iebli3b17dg5mkj4vu32sneo8mt9.apps.googleusercontent.com"
                                    onSuccess={handleSuccess}
                                    onFailure={handleError}
                                />
                            </div>
                            <div className="text-center mt-3">
                                <button className="btn btn-secondary" onClick={handleSwitchToLogin}>
                                    Already a member? Login with your email
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default RegistrationForm;
